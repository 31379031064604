import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image/withIEPolyfill';

const StyledComponentWrapper = styled.div`
  margin: 7.5rem 0 0;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
  grid-gap: 2rem;
`;

const Gallery = ({ items }) => {
  return (
    <StyledComponentWrapper>
      <Grid>
        {items.map(item => (
          <Img
            key={item.image.localFile.childImageSharp.fluid.src}
            fluid={item.image.localFile.childImageSharp.fluid}
          />
        ))}
      </Grid>
    </StyledComponentWrapper>
  );
};

export default Gallery;
