import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import Link from '../components/Link';
import Container from '../components/Container';
import Hero from '../components/Hero';
import ComponentWrapper from '../components/ComponentWrapper';
import withBackgroundPattern from '../components/helpers/withBackgroundPattern';
import Heading from '../components/typography/Heading';
import Layout from '../components/Layout';
import Cta from '../components/Cta';
import SEO from '../components/Seo';
import Gallery from '../components/Gallery';

const TitleBlock = styled(withBackgroundPattern)`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background: ${({ theme }) => theme.primaryColorDark};
  color: white;
  padding: 2.5rem;

  @media (${({ theme }) => theme.respondTo.mobile}) {
    a {
      margin: 0 0 2.5rem;
    }
  }

  @media (${({ theme }) => theme.respondTo.desktop}) {
    position: absolute;
    top: -30rem;
    left: -1rem;
    width: 38rem;
    height: 40rem;
    padding: 2.5rem 5rem 3.5rem;
    margin: 0;
  }
`;

const ContentWrapper = styled.article`
  max-width: 75rem;
  @media (${({ theme }) => theme.respondTo.desktop}) {
    margin: 10rem 0 0 auto;
  }
`;

const Meta = styled.ul`
  margin: 0 0 5rem;
  padding: 0;
  list-style: none;
`;

const MetaItem = styled.li`
  font-size: 2rem;
  span {
    display: inline-block;
    font-family: 'Karla';
    font-weight: bold;
    width: 175px;
  }
`;

const project = ({ data }) => {
  const {
    title,
    text,
    img,
    datum1,
    category,
    oppervlakte,
    gallery,
  } = data.prismicProjecten.data;
  return (
    <Layout>
      <SEO title={title.text} />
      <ComponentWrapper>
        <Hero img={img.localFile.childImageSharp.fluid} />
        <Container big>
          <TitleBlock>
            <Link back={1} white={1} to="/projecten/">
              Projectenoverzicht
            </Link>
            <Heading white>{title.text}</Heading>
          </TitleBlock>
        </Container>
      </ComponentWrapper>
      <Container>
        <ComponentWrapper>
          <ContentWrapper>
            <Meta>
              {category.text && (
                <MetaItem key={category.text}>
                  <span>Categorie:</span> {category.text}
                </MetaItem>
              )}
              {oppervlakte.text && (
                <MetaItem key={oppervlakte.text}>
                  <span>Oppervlakte:</span> {oppervlakte.text}
                </MetaItem>
              )}
              {datum1.text && (
                <MetaItem key={datum1.text}>
                  <span>Periode:</span> {datum1.text}
                </MetaItem>
              )}
            </Meta>
            <div dangerouslySetInnerHTML={{ __html: text.html }} />
          </ContentWrapper>
          <Gallery items={gallery} />
        </ComponentWrapper>
      </Container>
      <Cta />
    </Layout>
  );
};

export default project;

export const pageQuery = graphql`
  query project($id: String!) {
    prismicProjecten(id: { eq: $id }) {
      data {
        datum1 {
          text
        }
        category {
          text
        }
        oppervlakte {
          text
        }
        title {
          text
        }
        text {
          html
        }
        gallery {
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 700, quality: 80, maxHeight: 500) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        img {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 80, maxHeight: 700) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;
